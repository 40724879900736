<template>  
  <div :style="style" class="loader d-flex flex-column align-items-center justify-content-center">                              
    <div class="d-flex flex-column position-fixed align-items-center">                          
      <img class="mb-icon" v-if="$appGlobals.environment === 'production'" src="../assets/icon200x200.png"/>
      <img class="mb-icon" v-if="$appGlobals.environment === 'development'" src="../assets/icon200x200_dev.png"/>
      <img class="mb-icon" v-if="$appGlobals.environment === 'staging'" src="../assets/icon200x200_stage.png"/>      
      <div class="d-flex mt-4 spinnerAndText justify-content-center align-items-center">                          
        <template v-if="message">          
          <div class="msg">{{message}}</div>          
        </template>
      </div>
    </div>              
    <template v-if="spinVisible">          
      <font-awesome-icon class="spinner" spin :icon="['fad', 'spinner-third']"/>                    
    </template> 
    <span class="appVersion">{{appVersion}}</span>          
  </div>      
</template>

<script>
export default {
  props: {
    visible: { type: Boolean },        
    appVersion: { type: String, required: true},    
    spinVisible: { type: Boolean, default: true },
    message: { type: String, default: "..." },    
  },
  data() {
    return {
      doneWith: false,
    }
  },
  computed: {    
    style() {
      return {
        opacity: this.visible ? 1 : 0,
        visibility: this.visible ? "visible" :'hidden',
        pointerEvents: 'none'
      }      
    }
  },         
}
</script>

<style lang="scss">
@import "../variables";

.loader {
  z-index:998;
  position: fixed;
  background: $panelColour;
  color:$textColourHover;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity:1;
  transition:visibility 5.0s linear,opacity 1s linear;
  user-select: none;

  #canvas {
    height: 100%;
    position: fixed;    
  }

  .title {
    color:$textColour;    
    font-size: 2.0rem;  
    font-weight: 100;
  }
  /*padding: 20px;*/

  .holly {
    height: 2200px;
    width: 2200px;
    opacity: 0.8
  }

  .pointer-events-none {
     pointer-events: none;
  }

  .icon {
    bottom: 70px;
    right: 15px;
  }

  .mb-icon {
    width: 256px;
    height: 256px;
  }

  .elves {
    width: 500px;
    height: 500px;
    border-color:bisque;
    border-style: solid;
    border-radius: 10px 10px 10px 10px;
    border-width: 2px;
  }
  
  .spinner {
    position: fixed;
    bottom: 12px;
    left: 12px;
    color:$textColour;    
    font-size: 4.0rem;      
  }

  .spinnerAndText {
    height: 40px;
  }
    
  .appVersion {
    position: fixed;
    bottom: 12px;
    right: 12px;
    color:$textColour;        
  }

  .msg {
    font-size: 1.4em;        
  }

  .bottom-bar {
    position: fixed;        
    bottom: 0;        
    color:$textColourHover; 
    font-size: 1.2em;      
    width: 100%;
  }
}

</style>
